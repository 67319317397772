@import '../../colors.scss';

.accountSelector {
    justify-content: center;
    display: flex;

    &__overlay {
        position: absolute;
        max-height: 80vh;
        margin-top: 1em;
        margin-bottom: 0;
        top: 5vh;

        overflow-y: scroll;

        border: 4px double $light;
        box-shadow: 8px 8px black;

        background-color: $medium;

        z-index: 1;

        &.hidden {
            display: none;
        }

        &__list {
            padding: 1em;
        }
    }

    &__account {
        display: flex;
        gap: 1em;
        padding: 0.5em 0;
        width: 100%;
        justify-content: space-between;

        &:hover {
            color: $pink;
            cursor: pointer;
        }

        &__name {
            font-weight: 600;
        }

        &__name,&__balance {
            flex-shrink: 0;
        }

        &__address {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__accountInfo {
        display: flex;
        width: 100%;

        &__info {
            flex-shrink: 1;
        }

        &button {
            flex-basis: 1;
            flex-shrink: 0;
        }
    }
}